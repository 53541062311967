<template>
  <sm-page-loader v-if="isLoadingPage" />
  <sm-permission-error-message v-else-if="!hasPermission" />
  <div v-else class="support-request-info">
    <div class="support-request-info__chat-panel">
      <div class="support-request-info__navigate-block">
        <sm-breadcrumbs
          class="support-request-info__breadcrumbs"
          :items="breadcrumbs"
        />
        <router-link
          :to="{ name: 'SupportRequestsForCurrentUser' }"
          class="support-request-info__back-link"
          ><svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM17 7L1 7V9L17 9V7Z"
              fill="currentColor"
            />
          </svg>
          Назад</router-link
        >
      </div>
      <div class="support-request-info__chat">
        <div class="support-request-info__messages">
          <div
            v-for="(comment, commentIndex) in requestInfo.comments"
            :key="comment.id"
            :class="{
              'support-request-info__message--init': commentIndex === 0,
            }"
            class="support-request-info__message"
          >
            <div class="support-request-info__message-head">
              <p class="support-request-info__message-author">
                {{ comment.Name }}
              </p>
              <p class="support-request-info__message-added">
                {{ $moment(comment.added).format('DD.MM.YYYY HH:mm:ss') }}
              </p>
            </div>
            <p
              v-html="comment.text"
              class="support-request-info__message-text"
            ></p>
          </div>
        </div>
        <div class="support-request-info__input-block">
          <sm-textarea
            placeholder="Введите сообщение..."
            class="support-request-info__textarea"
          ></sm-textarea>
          <sm-button class="support-request-info__send-message-btn"
            ><svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.99982 17.9985H20.2498M8.99982 17.9985L4.90332 4.6875C14.7025 7.53701 23.9433 12.0387 32.2273 17.9985C23.9439 23.9592 14.7037 28.4619 4.90482 31.3125L8.99832 17.9985H8.99982Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </sm-button>
        </div>
      </div>
    </div>
    <div class="support-request-info__control-panel">
      <!-- <radio-buttons
        v-model="currentTabId"
        :buttons="tabs"
        class="support-request-info__tabs"
      /> -->
      <div class="support-request-info__tab-view">
        <!-- <component
          v-if="currentTabId"
          :is="currentTabId"
          :requestInfo="requestInfo"
        ></component> -->
        <support-request-chat-controls
          :requestInfo="requestInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import SmPageLoader from '@/components/common/SmPageLoader.vue';
import SmPermissionErrorMessage from '@/components/common/smPermissionErrorMessage.vue';
import SmBreadcrumbs from '@/components/common/breadcrumbs/SmBreadcrumbs.vue';
import SmTextarea from '@/components/common/forms/SmTextarea.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';
// import RadioButtons from '@/components/client-sites/RadioButtons.vue';
import SupportRequestChatControls from './SupportRequestChatControls.vue';

export default {
  name: 'SupportRequestInfo',

  components: {
    SmPageLoader,
    SmPermissionErrorMessage,
    SmBreadcrumbs,
    SmTextarea,
    SmButton,
    // RadioButtons,
    SupportRequestChatControls,
  },

  data() {
    return {
      isLoadingPage: false,
      breadcrumbs: [
        {
          text: 'Заявки',
          route: { name: 'RequestsMain' },
        },
        {
          text: 'Мои заявки',
          route: { name: 'SupportRequestsForCurrentUser' },
        },
        {
          text: `Заявка ${this.$route.params?.id || ''}`,
        },
      ],
      requestInfo: null,
      // tabs: [
      //   { id: 'SupportRequestChatControls', name: 'Чат' },
      //   { id: 'new', name: 'Новые' },
      //   { id: 'unanswered', name: 'Ожидание ответа' },
      //   { id: 'responseWaiting', name: 'Неотвеченные' },
      // ],
      // currentTabId: null,
    };
  },

  computed: {
    hasPermission() {
      if (!this.isLoadingPage && !this.requestInfo) return false;
      return true;
    },

    requestInfoParams() {
      if (!this.$route.params?.id) return null;

      const params = {
        reqlist: this.$route.params.id,
      }

      const hmode = this.$route.query?.hmode;

      if (!hmode) return params;

      return {
        ...params,
        hmode,
      }
    },
  },

  created() {
    this.isLoadingPage = true;

    if (!this.requestInfoParams) {
      this.isLoadingPage = false;
      return;
    }

    this.auth().then(() => {
      this.getRequestInfo(this.requestInfoParams)
        .then((requestInfo) => (this.requestInfo = requestInfo))
        .finally(() => {
          this.isLoadingPage = false;
        });
    });
  },

  methods: {
    ...mapActions({
      auth: 'krisha/auth',
      getRequestInfo: 'krisha/getRequestInfo',
    }),
  },
};
</script>

<style lang="scss">
.support-request-info button {
  user-select: none;

  &:active {
    opacity: 0.6;
  }
}

.support-request-info {
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: calc(100vh - 48px - 53px);
  overflow: hidden;
}

.support-request-info__chat-panel {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr;
  border-right: 1px solid #e3e3e3;
  box-shadow: 4px 10px 40px 0px #0000001a;
  overflow: hidden;
}

.support-request-info__navigate-block {
  border-bottom: 1px solid #e3e3e3;
}

.support-request-info__breadcrumbs {
  margin-bottom: 30px;
}

.support-request-info__back-link {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  gap: 5px;
  margin-bottom: 30px;
  color: #989898;

  &:hover {
    color: var(--blue);
  }
}

.support-request-info__chat {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 36px;
  gap: 30px;
  padding: 16px 35px 30px 0;
  overflow: hidden;
}

.support-request-info__messages {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  padding-right: 10px;
  overflow-y: auto;
}

.support-request-info__message {
  max-width: 60%;
  padding: 16px;
  border-radius: 16px 16px 4px 16px;
  background-color: #ccecf9;

  &--init {
    align-self: flex-start;
    border-radius: 16px 16px 16px 4px;
    background-color: #f1f1f1;
  }
}

.support-request-info__message-head {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 12px;
}

.support-request-info__message-author {
  font-weight: 300;
}

.support-request-info__message-added {
  opacity: 0.4;
}

.support-request-info__message-text {
  white-space: pre-line;
}

.support-request-info__input-block {
  display: grid;
  grid-template-columns: 1fr 36px;
  gap: 10px;
}

.support-request-info__textarea {
  height: 100%;
}

.support-request-info__send-message-btn {
  padding: 1px;
  background-color: white;
  border: 1px solid var(--gray);
  color: #989898;

  &:hover {
    background-color: var(--dark-blue);
    border-color: var(--dark-blue);
    color: var(--white);
  }
}

.support-request-info__control-panel {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 24px;
  overflow: hidden;
}

.support-request-info__tabs {
  padding-left: 36px;
}
</style>
